import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    fallbackLng: "ru",
    resources: {
      ru: {
        translation: {
          address: "Киев, Антоновича 29",
          navbar: {
            massage: "Массажи",
            courses: "Онлайн курсы",
            price: "Прайс",
            reviews: "Отзывы",
            contacts: "Контакты",
          },
          doctor: {
            button: "Записаться на прием",
            name: "bodymax.physiotherapy",
            surname: "Центр физиотерапии",
            caption:
              "BodyMax Physiotherapy - ваш ключ к здоровью и восстановлению. Наши опытные специалисты предлагают индивидуальные программы физиотерапии для вашего здоровья",
            desc_1: "Эксперт в области триггерных точек.",
            desc_2: "Специалист по головным болям.",
            desc_3: "Опыт работы 12 лет.",
          },
          massage_section_title: "Услуги",
          doctors_section_title: "Специалисты ",
          doctor_0: {
            name: "Бондарь Максим Витальевич",
            position: "Физиотерапевт, реабилитолог, хиропрактик",
            skill_1: "Эксперт в области тригерных точек.",
            skill_2: "Специалист по головным болям.",
            skill_3: "Опыт работы 12 лет.",
          },
          doctor_1: {
            name: "Лихошерстый Олег Анатольевич",
            position: "Физиотерапевт, кинезиотерапевт",
            skill_1: "Специалист в области диагностики суставов и мышц.",
            skill_2: "Точечный глубокотканный массаж триггерных точек.",
            skill_3:
              "Устранение сосудистых патологий (головокружение, головные боли, синдром позвоночной артерии).",
            skill_4:
              "Восстановление искривленной осанки (гиперлордоз, сколиоз, сутулость).",
            skill_5:
              "Мануальные техники при защемлении позвоночника и суставов.",
          },
          doctor_2: {
            name: "Гетманов Денис Валентинович",
            position: "Реабилитолог, массажист",
            skill_1: "Специалист по триггерным точкам.",
            skill_2: "Эксперт в области миопрессуры.",
            skill_3:
              "Специализируется на силовом глуботканном массаже.",
          },
          courses_section_title: "Онлайн курсы",
          neck_course: {
            title: "Онлайн курс по восстановлению шейного отдела позвоночника",
            text: "В данный курс входит реабилитация шейного и грудного отдела позвоночника. Курс состоит из 12 частей длительностью 2 часа. Этап реабилитации займет 2 недели, с четким графиком и всеми рекомендациями. Кому подойдет этот курс?",
            item_1:
              "Для людей у кого есть шейный остеохондроз, осложненный протрузиями и межпозвонковыми грыжами.",
            item_2: "Людям - у которых хрустит, щелкает шея.",
            item_3: "При проблемах кровообращения и зажатых сосудах шеи.",
            item_4: "При головных болях.",
            item_5: "Людям, с миофасциальным триггерным синдромом.",
          },
          anathomy_course: {
            title:
              "Анатомия человека (мышцы и их движения) нервы, сосуды. В 3д",
            text: "В данном курсе:",
            item_1:
              "вы сможете выучить название мышц человека. Увидеть их место крепления и расположение конкретной мышцы.",
            item_2: "разберётесь в иннервации и  кровоснабжении мышц.",
            item_3:
              "научитесь понимать какие движения выполняет конкретная мышца, как работает с другими мышцами.",
            additional_text:
              "Это онлайн видеообучение. После оплаты вам придёт ссылка с безлимитным доступом на курс. Видео вы сможете скачать или просмотреть в любое время. Курс по анатомии состоит из 22 частей. Каждая часть посвящена отдельному разбору участка тела: голова, шея, спина, плечи, руки, ноги, живот. Длительность одного видео, в среднем 10 - 12 минут. Видеокурс 'Анатомия человека' на столько понятен и легко усвояем, что в нем сможет разобраться обыватель и человек без мед образования.",
          },
          trigger_points_course: {
            title: "Массаж по триггерным точкам (Про версия)",
            text: "Научиться триггерному массажу может каждый, ведь этот видеокурс содержит профессиональные техники миопрессуры, которые удачно деактивируют триггеры и восстанавливают тонус мышц. Видеокурс состоит из 3 частей:",
            item_1:
              "1 часть – Массажные зоны и анатомические участки. В ней показал расположение позвонков, нервов, сосудов, мышц, наличие триггерных точек.",
            item_2:
              "2 часть – Техники и приемы массажа. Смотрите и повторяйте за мной, как я делаю. Направления и составляющие всех массажных движений.",
            item_3:
              "3 часть – Общение с клиентом. Важная часть, доверия пациента и массажиста. Правила общения.",
          },
          percussion_course: {
            title: "Методика перкуссионного массажа",
            text: "Массажные технологии развиваются, а вы как специалист, хотите расти и развиваться вместе с ними. Для этого я создал видеокурс по применению массажного пистолета. В данном видеокурсе, длительностью 1.5 часа, вы сможете разобрать и выучить такие моменты:",
            item_1:
              "Как подобрать массажный пистолет, на что ориентироваться перед покупкой. Сравнить с другими аппаратами.",
            item_2:
              "Как пользоваться насадками к пистолету, где их применять, где лучше избегать,  чтоб не было обострений.",
            item_3:
              "Сможете выучить анатомию. Увидете расположения  мышц. Сможете грамотно выполнить массаж.",
            item_4:
              "Научитесь правильно работать с триггерными точками при миофасциальном синдроме.",
            item_5:
              "Сможете применять данные техники дома, восстанавливать родных, так и в профессиональной массажной практике.",
            additional_text:
              "Весь профессиональный опыт за 5 лет работы массажным пистолетом, я собрал в одном курсе. А вы сможете усвоить мой опыт, всего за 1.5 часа.",
          },
          panic_attacks_course: {
            title: "Методики и техники устранения панических атак",
            text: "Если у вас частые головокружения, болит голова, бессонница. Во время засыпания вы слышите свое сердцебиение и испытываете чувство тревоги. Если все эти симптомы у вас есть? - тогда этот видеокурс точно для вас. В этом видеокурсе:",
            item_1:
              "Вы научитесь снимать приступы панической атаки. Вам больше не придётся ждать скорую, вы сами все сможете сделать.",
            item_2:
              "Научитесь контролировать свое состояние и не доводить себя до приступов.",
            item_3:
              "За месяц количество приступов уменьшаться во много раз и даже вовсе могут исчезнуь.",
            item_4:
              "При соблюдении всех рекомендаций вы улучшите состояние своего организма, нормализуете сон и жизненную энергию.",
            item_5: "Курс рассчитан на 1 месяц.",
          },
          treatment_results_section_title: "Наши результаты",
          treatment_results: {
            before: "До",
            after: "После",
          },
          prices_section_title: "Прайс",
          prices_options: {
            item_1: "от",
            item_2: "за отдел",
            item_3: "зона",
            item_4: "курс индивидуально",
          },
          prices_section_subtitle_2:
            "все дополнительные процедуры выполняются на усмотрение специалистов для более быстрого и безопасного результата !",
          prices_text: "зона от",
          feedback_modal: {
            title: "Оставить заявку",
            subtitle:
              "Оставьте номер телефона и мы обязательно свяжемся с вами.",
            name_placeholder: "Введите имя",
            button: "Отправить",
          },
          errors: {
            empty_field: "Заполните это поле",
            invalid_name: "Имя не должно содержать цифр и спецсимволов",
          },
          reviews_section_title: "Отзывы",
          reviews: {
            novikova: {
              name: "Юлия Новикова",
              status: "Пенсионер",
              text: "Огромная благодарность за ценную информацию. Максим - профессионал своего дела. Только самые актуальные темы. Спасибо, что спасли мое здоровье!",
            },
            fedorov: {
              name: "Олег Федоров",
              status: "Предприниматель",
              text: "Максим, спасибо за Ваш труд, старания и граммотную, доступную подачу материала! Вы помогли мне вылечить плечо, которое болело уже на протяжении 1 года.",
            },
          },
          socials_section_title: "Подписывайтесь и будьте в курсе",
          socials: {
            subscribers: "Подписчиков",
            button: "Подписаться",
          },
          contacts_section_title: "Контакты",
          contacts: {
            address: {
              title: "Адрес",
              text: "г. Киев, метро Олимпийская, Антоновича 29",
            },
            schedule: {
              title: "Режим работы",
              item_1: "пн-сб с 10:00 до 20:00",
              item_2: "вс - выходной",
            },
          },
          buy_button: "Купить",
          reviews_button: "Отзывы",
          preview_video_button: "Смотреть видео о курсе",
          more_courses_button: "Больше курсов",
          contact_us_button: "Написать нам",
          apps: {
            body_max_app_description:
              "BodyMax.Rehabilitation - это приложение, которое содержит комплекс упражнений по восстановлению опорно-двигательного аппарата человека. Реабилитация при остеохондрозе, межпозвоночных грыжах, сколиозе, сутулости, при онемении пальцев рук, нарушении мозгового кровообращения и защемлениях в спине, проблемах суставов и многих других паталогиях. Данные комплексы разработаны с помощью уникальных движений, направленных на реабилитацию человека. Все они носят оздоровительный эффект, улучшая кровообращения в мышцах, устраняя защемление нерва, восстанавливая мозговое кровообращение, стабилизируя давление, нормализуют подвижность суставов. Реабилитационные занятия можно выполнять в домашних условиях, имея минимальный набор реабилитационного оборудования (резинка/жгут, теннисный мяч, палка).",
            trigger_points_app_description:
              "Данное приложение содержит размещение триггерных точек на проекции тела человека, и упражнения по обезболиванию мышц. Вы можете увидеть каждую мышцу и ее боль. У вас будут все инструменты для устранения триггерных точек.",
          },
          apps_download_button: "Скачать",
        },
      },
      uk: {
        translation: {
          address: "Київ, Антоновича 29",
          navbar: {
            massage: "Масаж",
            courses: "Онлайн курси",
            price: "Прайс",
            reviews: "Відгуки",
            contacts: "Контакти",
          },
          doctor: {
            button: "Записатися на прийом",
            name: "bodymax.physiotherapy",
            surname: "Центр фізіотерапії",
            caption:
              "BodyMax Фізіотерапія - ваш ключ до здоров'я та відновлення. Наші досвідчені фахівці пропонують індивідуальні програми фізіотерапії для вашого здоровʼя",
            desc_1: "Експерт у галузі тригерних точок",
            desc_2: "Фахівець з головного болю",
            desc_3: "Досвід роботи 12 років",
          },
          massage_section_title: "Послуги",
          doctors_section_title: "Фахівці",
          doctor_0: {
            name: "Бондарь Максим Віталійович",
            position: "Фізіотерапевт, реабілітолог, хіропрактик",
            skill_1: "Експерт з тригерних точок.",
            skill_2: "Спеціаліст з головних болей.",
            skill_3: "Досвід роботи 12 років.",
          },
          doctor_1: {
            name: "Лихошерстий Олег Анатолійович",
            position: "Фізіотерапевт, кінезіотерапевт",
            skill_1: "Фахівець у галузі діагностики суглобів та м'язів.",
            skill_2: "Точковий глибокотканинний масаж тригерних точок.",
            skill_3:
              "Усунення судинних патологій (запаморочення, головний біль, синдром хребцевої артерії).",
            skill_4:
              "Відновлення викривленої постави (гіперлордоз, сколіоз, сутулість).",
            skill_5:
              "Відновлення викривленої постави (гіперлордоз, сколіоз, сутулість).",
          },
          doctor_2: {
            name: "Гетманов Денис Валентинович",
            position: "Реабілітолог, масажист",
            skill_1: "Фахівець з тригерних точок.",
            skill_2: "Експерт у галузі міопресури.",
            skill_3:
              "Спеціалізується на силовому глибокотканинному масажі.",
          },
          courses_section_title: "Курси",
          neck_course: {
            title: "Онлайн курс відновлення шийного відділу хребта",
            text: "У даний курс входить реабілітація шийного та грудного відділу хребта. Курс складається із 12 частин тривалістю 2 години. Етап реабілітації займе 2 тижні, з чітким графіком та всіма рекомендаціями. Кому підійде цей курс?",
            item_1:
              "Для людей у   кого є шийний остеохондроз, ускладнений протрузіями та міжхребцевими грижами.",
            item_2: "Людям - у яких хрумтить, клацає шия.",
            item_3: "При проблемах кровообігу та затиснутих судинах шиї.",
            item_4: "При головних болях.",
            item_5: "Людям з міофасціальним тригерним синдромом.",
          },
          anathomy_course: {
            title: "Анатомія людини (м'язи та їх рух), нерви, судини. У 3д.",
            text: "У цьому курсі:",
            item_1:
              "ви зможете вивчити назву м'язів людини. Побачити їх місце кріплення та розташування конкретного м'яза.",
            item_2: "розберетеся в іннервації та кровопостачання м'язів.",
            item_3:
              "навчитеся розуміти які рухи виконує конкретний м'яз, як працює з іншими м'язами.",
            additional_text:
              "Це онлайн відеонавчання. Після сплати вам прийде посилання з безлімітним доступом на курс. Відео ви зможете завантажити або переглянути у будь-який час. Курс анатомії складається з 22 частин. Кожна частина присвячена окремому розбору ділянки тіла: голова, шия, спина, плечі, руки, ноги, живіт. Тривалість одного відео, в середньому 10 – 12 хвилин. Відеокурс 'Анатомія людини' на стільки зрозумілий і легко засвоюємо, що в ньому зможе розібратися обиватель і людина без медичної освіти.",
          },
          percussion_course: {
            title: "Методика перкусійного масажу",
            text: "Масажні технології розвиваються, а ви як фахівець, хочете рости та розвиватися разом з ними. Для цього я створив відеокурс із застосування масажного пістолета. У даному відеокурсі, тривалістю 1.5 години, ви зможете розібрати та вивчити такі моменти:",
            item_1:
              "Як підібрати масажний пістолет, на що орієнтуватися перед покупкою. Порівняти з іншими апаратами.",
            item_2:
              "Як скористатися насадками до пістолета, де їх використовувати, де краще уникати, щоб не було загострень.",
            item_3:
              "Чи зможете вивчити анатомію. Побачите розташування м'язів. Чи зможете грамотно виконати масаж.",
            item_4:
              "Навчитеся правильно працювати з тригерними точками при міофасціальному синдромі.",
            item_5:
              "Чи зможете застосовувати дані техніки вдома, відновлювати рідних, так і в професійній масажній практиці.",
            additional_text:
              "Весь професійний досвід за 5 років роботи масажним пістолетом я зібрав в одному курсі. А ви зможете засвоїти мій досвід лише за 1.5 години.",
          },
          trigger_points_course: {
            title: "Масаж по тригерним точкам (Про версія)",
            text: "Навчитися тригерному масажу може кожен, адже цей відеокурс містить професійні техніки з міопресури, які вдало деактивують тригери та відновлюють тонус мʼязів. Відеокурс складається з 3 частин:",
            item_1:
              "1 частина - Масажні зони та анатомічні ділянки. У ній показав розташування хребців, нервів, судин, м'язів, наявність тригерних точок.",
            item_2:
              "2 частина - Техніки та прийоми масажу. Дивіться та повторюєте за мною, як я роблю. Напрямки та складову всіх масажних рухів.",
            item_3:
              "3 частина - Спілкування з клієнтом. Важлива частина, довіри пацієнта та масажиста. Правила спілкування.",
          },
          panic_attacks_course: {
            title: "Методики та техніки усунення панічних атак",
            text: "Якщо у вас часті запаморочення, болить голова, безсоння. Під час засинання ви чуєте своє серцебиття і відчуваєте тривогу. Якщо усі ці симптоми у вас є? - Тоді цей відеокурс точно для вас. У цьому відеокурсі:",
            item_1:
              "Ви навчитеся знімати напади панічної атаки. Вам більше не доведеться чекати на швидку, ви самі все зможете зробити.",
            item_2:
              "Навчіться контролювати свій стан і не доводити себе до нападів.",
            item_3:
              "За місяць кількість нападів зменшуватиметься багато разів і навіть зовсім можуть зникнути.",
            item_4:
              "За дотримання всіх рекомендацій ви покращите стан свого організму, нормалізуєте сон і життєву енергію.",
            item_5: "Курс розрахований на 1 місяць",
          },
          treatment_results_section_title: "Наші результати",
          treatment_results: {
            before: "До",
            after: "Після",
          },
          prices_section_title: "Прайс",
          prices_options: {
            item_1: "від",
            item_2: "за відділ",
            item_3: "зона",
            item_4: "курс індивідуально",
          },
          prices_section_subtitle_2:
            "всі додаткові процедури виконуються на розсуд спеціалістів для більш швидкого та безпечного результату!",
          prices_text: "зона від",
          feedback_modal: {
            title: "Залишити заявку",
            subtitle:
              "Залишіть номер телефону, і ми обов'язково зв'яжемося з вами",
            name_placeholder: "Ваше ім'я",
            button: "Надіслати",
          },
          errors: {
            empty_field: "Заповніть це поле",
            invalid_name: "Ім'я повинно містити тільки літери",
          },
          reviews_section_title: "Відгуки",
          reviews: {
            novikova: {
              name: "Юлія Новікова",
              status: "Пенсіонер",
              text: "Дуже вдячна за цінну інформацію. Максим - професіонал своєї справи. Лише найактуальніші теми. Дякую, що врятували моє здоров'я!",
            },
            fedorov: {
              name: "Олег Федоров",
              status: "Підприємець",
              text: "Максиме, дякую за Ваш труд, старания и граммотную, доступную подачу материала! Вы помогли мне вылечить плечо, которое болело уже на протяжении 1 года.",
            },
          },
          socials_section_title: "Підпишіться і будьте в курсі",
          socials: {
            subscribers: "Підписників",
            button: "Підписатися",
          },
          contacts_section_title: "Контакти",
          contacts: {
            address: {
              title: "Адреса",
              text: "м. Київ, метро Олімпійська, Антоновича 29",
            },
            schedule: {
              title: "Графік роботи",
              item_1: "пн-сб з 10:00 до 20:00",
              item_2: "нд - вихідний",
            },
          },
          buy_button: "Купити",
          reviews_button: "Відгуки",
          preview_video_button: "Переглянути відео про курс",
          more_courses_button: "Більше курсів",
          contact_us_button: "Написати нам",
          apps: {
            body_max_app_description:
              "BodyMax.Rehabilitation - це застосунок, який містить комплекс вправ з відновлення опорно-рухового апарату людини. Реабілітація при остеохондрозі, міжхребцевих грижах, сколіозі, сутулості, при онімінні пальців рук, порушенні мозкового кровообігу та затисканнях у спині, проблемах суглобів та багатьох інших патологіях. Ці комплекси розроблені за допомогою унікальних рухів, спрямованих на реабілітацію людини. Усі вони мають оздоровчий ефект, покращуючи кровообіг у м'язах, усуваючи защемлення нерва, відновлюючи мозковий кровообіг, стабілізуючи тиск, нормалізують рухливість суглобів. Реабілітаційні заняття можна виконувати в домашніх умовах, маючи мінімальний набір реабілітаційного обладнання (резинка/джгут, тенісний м'яч, палиця).",
            trigger_points_app_description:
              "Даний додаток містить розміщення тригерних точок на проекції тіла людини, та вправи щодо знеболювання м'язів. Ви можете побачити кожен м'яз та його біль. У вас будуть усі інструменти для усунення тригерних точок.",
          },
          apps_download_button: "Завантажити",
        },
      },
    },
  });

export default i18n;
