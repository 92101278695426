import React from 'react';
import { Box, Typography } from '@material-ui/core';
import './separate-review.scss';

interface IProps {
	avatar: string;
	name: string;
	status: string;
	review: string;
}

export const SeparateReview: React.FC<IProps> = ({ avatar, name, status, review }) => {
	return (
		<Box display="flex" flexDirection="column" className="review-card">
			<Box display="flex" className="review-card__header">
				<img src={avatar} alt={name}/>
				<Box display="flex" flexDirection="column" justifyContent="center">
					<Typography component="h5" variant="body2" color="primary">
						{name}
					</Typography>
					<Typography component="p" color="textPrimary">{status}</Typography>
				</Box>
			</Box>
			<Typography component="p" variant="body1" color="textPrimary" className="review-card__text">
				{review}
			</Typography>
		</Box>
	)
}
