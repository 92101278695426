import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import { AppContainer } from "./containers/AppContainer";
import { ThemeProvider, createTheme } from "@material-ui/core";

import "./i18n";

const theme = createTheme({
  typography: {
    fontFamily: "Raleway, Arial",
    h1: {
      fontSize: "60px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "48px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "48px",
      fontWeight: 700,
    },
    h5: {
      fontSize: "28px",
      fontWeight: 700,
    },
    h6: {
      fontSize: "34px",
      fontWeight: 700,
    },
    body1: {
      fontSize: "18px",
      fontWeight: 500,
    },
    body2: {
      fontSize: "18px",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.3,
    },
  },
  palette: {
    primary: {
      main: "#443D3D",
    },
    secondary: {
      main: "#987656",
    },
    text: {
      primary: "#777170",
      secondary: "#a19e9e",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppContainer />
    </ThemeProvider>
  </React.StrictMode>
);
