import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Container, Hidden, Typography } from "@material-ui/core";

import { SeparateReview } from "../SeparateReview/SeparateReview";

import novikova from "../../assets/images/reviews-cards/novikova.svg";
import fedorov from "../../assets/images/reviews-cards/fedorov.svg";
import karpenko from "../../assets/images/reviews-cards/karpenko.svg";
import krasnenkova from "../../assets/images/reviews-cards/krasnenkova.svg";
import fomin from "../../assets/images/reviews-cards/fomin.svg";
import volnyi from "../../assets/images/reviews-cards/volnyi.svg";

import "./reviews-section.scss";

const reviews = [
  {
    id: 1,
    avatar: novikova,
    name: "Юлия Новикова",
    status: "Пенсионер",
    review:
      "Огромная благодарность за ценную информацию. Максим - профессионал своего дела. Только самые актуальные темы. Спасибо, что спасли мое здоровье!",
  },
  {
    id: 2,
    avatar: fedorov,
    name: "Олег Федоров",
    status: "Предприниматель",
    review:
      "Максим, спасибо за Ваш труд, старания и граммотную, доступную подачу материала! Вы помогли мне вылечить плечо, которое болело уже на протяжении 1 года.",
  },
  {
    id: 3,
    avatar: karpenko,
    name: "Ирина Карпенко",
    status: "Менеджер по продажам",
    review:
      "Спасибо! Если бы не Вы, я бы так и не нашла бы настоящую причину болезней и постоянного дискомформа. Благодарю Вас за опыт, которым Вы делитесь, все доступно и понятно!",
  },
  {
    id: 4,
    avatar: krasnenkova,
    name: "Светлана Красненкова",
    status: "Учитель",
    review:
      "Максим, хочу поблагодарить Вас за упражнения. Я почти убрала перекос таза и скалиоз сходит на нет. Развернулась грудная клетка. Я еще на пути к выздоровлению. Спасибо Вам.",
  },
  {
    id: 5,
    avatar: fomin,
    name: "Кирилл Фомин",
    status: "SMM-специалист",
    review:
      "Я очень благодарен Вам, Максим! У меня сидячая работа и постоянно болит спина. После Ваших рекомендаций на онлайн консультации все прошло. Спасибо, что делаете людей счастливее!",
  },
  {
    id: 6,
    avatar: volnyi,
    name: "Ростислав Вольный",
    status: "Строитель",
    review:
      "Благодарю Вас от всей души! Наконец-то нашел ответы на свои вопросы. Врачи никогда ничего не объясняют, а колят диклофенак или гормоны. Успехов и процветания Вам!",
  },
];

export const ReviewsSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 7000,
    cssEase: "ease",
    draggable: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          dots: true,
          waitForAnimate: false,
          swipe: true,
          autoplay: false,
        },
      },
    ],
  };

  const { t } = useTranslation();

  return (
    <section className="reviews" id="reviews">
      <Container fixed>
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="reviews__title"
        >
          {t("reviews_section_title")}
        </Typography>
      </Container>
      <Hidden xsDown>
        <Slider {...settings} className="reviews__slider">
          {reviews.map(({ id, ...restProps }) => (
            <SeparateReview key={id + restProps.name} {...restProps} />
          ))}
        </Slider>
      </Hidden>
      <Hidden smUp>
        <Container fixed>
          <Slider {...settings} className="reviews__slider">
            {reviews.map(({ id, ...restProps }) => (
              <SeparateReview key={id + restProps.name} {...restProps} />
            ))}
          </Slider>
        </Container>
      </Hidden>
    </section>
  );
};
