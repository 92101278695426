import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import mastercard from "../../assets/images/mastercard.svg";
import visa from "../../assets/images/visa.svg";

import "./footer.scss";

export const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  return (
    <Container fixed component="footer" className="footer">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="footer__top-wrapper"
      >
        <Box display="flex" component="nav" textAlign="center">
          <Link to="/privacy">
            Договор Оферты и Политика Конфиденциальности
          </Link>
        </Box>
        <Typography
          component="p"
          color="textPrimary"
          className="footer__copyright"
        >
          © {year}. Body Max Physiotherapy. All Rights Reserved
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="footer__bottom-wrapper"
      >
        <div className="footer__payment-systems">
          <img src={mastercard} alt="Mastercard"/>
          <img src={visa} alt="Visa"/>
        </div>
      </Box>
    </Container>
  );
};
