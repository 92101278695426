import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Footer } from '../Footer/Footer';
import { animateScroll } from 'react-scroll';
// import logo from '../../assets/images/bodymax-logo.svg';
import './privacy.scss';

const items = [
	{
		id: 1,
		title: "1. Общие положения",
		text: "",
		subtext: [
			"1.1. Договорные отношения между Исполнителем и Заказчиком оформляются в виде Договора публичной оферты. Оплата услуг означает, что Заказчик  заключил Договор публичной оферты, условия которого указаны ниже.",
			"1.2. Договор публичной оферты является публичным, то есть в соответствии со статьями 633, 641 Гражданского кодекса Украины его условия одинаковы для всех Заказчиков независимо от статуса (физическое лицо, юридическое лицо, физическое лицо-предприниматель). Заказчик принимает условия и порядок оформления заказа и оплаты.",
			"1.3. Настоящий Договор вступает в силу с момента предоплаты услуг (даты зачисления на расчетный счет Исполнителя) и действует до момента получения услуг Заказчиком.",
			"1.4. Договорные отношения между сторонами регулируются законодательством Украины.",
		]
	},
	{
		id: 2,
		title: "2. Термины",
		text: "",
		subtext: [
			"«Договор публичной оферты» - публичный договор, образец которого размещен на Веб-сайте https://bodymax.online/ предназначенный  неопределенному кругу лиц (далее – Договор).",
			"«Акцепт» - принятие Заказчиком предложения Заказчика об оказании услуг.",
			"«Услуга» – любая услуга Исполнителя, вид и стоимость которой определена на на Веб-сайте https://bodymax.online/, по предоставлению ограниченного доступа Заказчика к Сайту в соответствии с условиями Оферты для оказания информационно-консультационных услуг.",
			"«Заказчик» - любое дееспособное физическое, юридическое лицо, физическое лицо-предприниматель, заключившее Договор.",
			"«Исполнитель» - ФЛП Бондарь Максим Витальевич, ЕГРПОУ: 3390314135,	Счет IBAN: UA063524790000026003052233557, АО КБ «ПРИВАТБАНК» 01033 Украина, Голосеевский р-н, ул Антоновича, д. 29.	Веб-сайт https://bodymax.online/.", 
			" «Законодательство» - установленные украинским законодательством нормы для регулирования договорных отношений.",
		]
	},
	{
		id: 3,
		title: "3. Предмет договора",
		text: "",
		subtext: [
			"3.1. Исполнитель обязуется на условиях и в порядке, определенных настоящим Договором предоставить услуги, а Заказчик обязуется на условиях и в порядке, определенных настоящим Договором , оплатить услуги.",
			"3.2. Исполнитель подтверждает, что имеет все необходимые разрешения на осуществление хозяйственной деятельности, которые регулируют сферу правоотношений, возникающих и действующих в процессе исполнения настоящего Договора, а также гарантирует, что имеет право на оказание услуг без каких-либо ограничений, в соответствии с требованиями действующего законодательства Украины, и обязуется нести ответственность в случае нарушения прав Заказчика в процессе исполнения настоящего Договора.",
		]
	},
	{
		id: 4,
		title: "4. Права и обязанности исполнителя",
		text: "",
		subtext: [
			`4.1. Исполнитель обязан: \n • выполнять условия настоящего Договора; \n • выполнять заказы Заказчика после поступления оплаты от Заказчика;`,
			"4.2. Исполнитель имеет право: \n • требовать от Заказчика выполнения условий настоящего Договора;",
		]
	},
	{
		id: 5,
		title: "5. Права и обязанности заказчика",
		text: "",
		subtext: [
			`5.1. Заказчик обязан: \n • выполнять условия настоящего Договора;`,
			"5.2. Заказчик имеет право: \n • ознакомиться с информацией об услугах, размещенной на Веб-сайте https://bodymax.online/ \n • оплатить и получить услуги на условиях настоящего Договора; \n • требовать от Исполнителя выполнения условий настоящего Договора;",
		]
	},
	{
		id: 6,
		title: "6. Цена договора и порядок оплаты",
		text: "",
		subtext: [
			`6.1. Цена каждой отдельной услуги определяется Исполнителем и указывается на соответствующей странице Веб-сайта https://bodymax.online/. Цена Договора определяется путем сложения цен всех выбранных услуг.`,
			"6.2. Стоимость Заказа может изменяться в зависимости от цены и количества услуг.",
			"6.3. Заказчик может оплатить заказ следующими способами:",
			"6.3.1. с помощью перевода денежных средств на счёт Исполнителя, заполнив платёжную форму на сайте;",
			"6.3.2. с помощью перевода денежных средств на счёт Исполнителя через приложение «Приват 24»;",
			"6.3.3. с помощью перевода денежных средств на счёт Исполнителя, отсканировав QR-код платёжной формы;",
			"6.3.4. с помощью перевода денежных средств на счёт Исполнителя, используя сервис «Google Pay».",
		]
	},
	{
		id: 7,
		title: "7. Условия возврата товара и денежных средств",
		text: "",
		subtext: [
			`7.1. Возврат денежных средств предусматривается только в случае непредоставления Исполнителем оплаченных Заказчиком  услуг.`,
			"7.2. Возврат денежных средств осуществляется посредством возврата стоимости оплаченных услуг почтовым, банковским переводом, а также путем перечисления на банковскую карту (карта должна быть эмитирована на территории Украины; валюта карты - гривна).",
		]
	},
	{
		id: 8,
		title: "8. Ответственноть сторон и разрешение споров",
		text: "",
		subtext: [
			`8.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение условий настоящего Договора в порядке, предусмотренном настоящим Договором и действующим законодательством.`,
			"8.2. В случае возникновения споров, связанных с выполнением Сторонами настоящего Договора,  Стороны обязуются решать их путем переговоров с соблюдением претензионного порядка. Срок рассмотрения претензии - 7 (семь) календарных дней с даты ее получения.",
			"8.3. Все споры, разногласия или требования, возникающие из настоящего Договора или в связи с ним, в том числе касающиеся его исполнения, нарушения, прекращения или недействительности, подлежат разрешению в соответствующем суде согласно с законодательством.",
		]
	},
	{
		id: 9,
		title: "9. Форс-мажорные обстоятельства",
		text: "",
		subtext: [
			`9.1. Стороны не несут ответственности за невыполнение любого из своих обязательств, за исключением обязательств по оплате, если докажут, что такое неисполнение было вызвано форс-мажорными обстоятельствами, то есть событиями или обстоятельствами, которые действительно находятся вне контроля такой стороны, наступившие после заключения настоящего Договора, носящие непредсказуемый и неотвратимый характер. \n
			К форс-мажорным обстоятельствам относятся, в частности, природные катаклизмы, забастовки, пожары, наводнения, взрывы,  войны (как объявленные, так и необъявленные), мятежи, ограничения, накладываемые государственными органами (включая распределения, приоритеты, официальные требования, квоты и ценовой контроль), если эти обстоятельства непосредственно повлияли на исполнение настоящего Договора.
			`,
			"9.2. Сторона, для которой стало невозможным выполнение обязательств по настоящему Договору из-за наступления форс-мажорных обстоятельств, должна немедленно информировать другую Сторону в письменном виде о возникновении вышеуказанных обстоятельств, а также в течение 30 (тридцати) календарных дней предоставить другой Стороне подтверждение форс-мажорных обстоятельств. Таким подтверждением будет справка, сертификат или другой соответствующий документ, выданный уполномоченным государственным органом, расположенным по месту возникновения форс-мажорных обстоятельств.",
			"9.3. Время, которое требуется Сторонам для выполнения своих обязательств по настоящему Договору, будет продлено на любой срок, в течение которого было отложено исполнение из-за перечисленных обстоятельств.",
			"9.4. Если из-за действия обстоятельств непреодолимой силы неисполнение обязательств по настоящему Договору продолжается более трех месяцев, каждая из Сторон вправе расторгнуть настоящий Договор в одностороннем порядке, письменно уведомив об этом другую сторону.",
			"Не смотря на наступление форс-мажора, перед прекращением настоящего Договора вследствие форс-мажорных обстоятельств Стороны осуществляют окончательные взаиморасчеты.",
		]
	},
	{
		id: 10,
		title: "10. Прочие условия договора",
		text: "",
		subtext: [
			`10.1. Информация, предоставляемая Заказчиком, является конфиденциальной. Информация о Заказчике используется исключительно в целях выполнения его Заказа.`,
			"10.2. Собственным акцептированием Договора или регистрацией на Веб-сайте https://bodymax.online/ Заказчик добровольно дает согласие на сбор и обработку своих персональных данных с последующей целью: данные, которые становятся известны, будут использоваться в коммерческих целях, в том числе для обработки заказов на оказание услуг, получение информации о заказе, рассылки телекоммуникационными средствами связи (электронной почтой, мобильной связью) рекламных и специальных предложений, информации об акциях, розыгрыши или любой другой информации о деятельности Веб-сайта https://bodymax.online/ \n\n Для целей, предусмотренных настоящим пунктом, Заказчику могут направляться письма, сообщения и материалы на почтовый адрес, e-mail Заказчика, а также отправляться sms-сообщения, осуществляться звонки на телефонный номер Заказчика.",
			"10.3. Заказчик дает право осуществлять обработку его персональных данных, в том числе: помещать персональные данные в базы данных (без дополнительного уведомления об этом), осуществлять пожизненное хранение данных, их накопление, обновление, изменение (по мере необходимости). Исполнитель обязуется обеспечить защиту данных от несанкционированного доступа третьих лиц, не распространять и не передавать данные любой третьей стороне (кроме передачи данных связанным лицам, коммерческим партнерам, лицам, уполномоченным Исполнителем на осуществление непосредственной обработки данных для указанных целей, а также на обязательный запрос компетентного государственного органа).",
			"10.4. В случае нежелания получать рассылку, Заказчик имеет право обратиться к Исполнителю, написав заявление об отказе от получения рекламных материалов, направив его на почтовый или электронный адрес.",
			"10.5. Исполнитель не несет ответственности за содержание и достоверность информации, предоставляемой Заказчиком при оформлении заказа. Заказчик несет ответственность за достоверность указанной при оформлении заказа информации.",
		]
	},
];

export const Privacy: React.FC = () => {
	useEffect(() => {
		animateScroll.scrollToTop();
	}, []);

	return (
		<>
			<Container fixed>
				<Box className="privacy-navbar" id="navbar">
					<Link to="/">
						{/* <img src={logo} alt="Bodymax" className="privacy-navbar__logo"/> */}
					</Link>
				</Box>
				<Box className="privacy-content">
					<Typography component="h1" variant="h2" color="primary">
						Договор Оферты и Политика Конфиденциальности
					</Typography>
					<Typography variant="body1" component="p" color="textPrimary" className="subtext">
						Согласно с условиями настоящего Договора Исполнитель с одной стороны, и любое лицо, принявшее условия настоящего Договора публичной оферты - Заказчик, с другой стороны, далее совместно именуемые - Стороны, заключили настоящий Договор публичной оферты (далее - Договор), адресованный неограниченному кругу лиц, являющийся официальным публичным предложением Исполнителя заключить с Заказчиками договор оказания услуг, информация о которых размещена на Веб-сайте Исполнителя  https://bodymax.online/.
					</Typography>
					<ul>
						{items.map(({ id, title, text, subtext }) => (
							<li key={id}>
								<Typography variant="h5" component="h3" color="primary">
									{title}
								</Typography>
								{subtext.map((text, idx) => (
									<li key={id + idx}>
										<Typography variant="body1" component="p" color="textPrimary" className="subtext">
											{text}
										</Typography>
									</li>
								))}
							</li>
						))}
					</ul>
				</Box>
			</Container>
			<Footer />
		</>
	)
}
